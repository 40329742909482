import * as React from 'react';
import axios from 'axios';
import classNames from 'classnames';
import { useForm } from 'react-hook-form';
import { Layout } from '../../../components/layout';
import { Seo } from '../../../components/seo';
import * as Styles from '../../../styles/pages/_login.module.scss';
import * as CommonStyles from '../../../styles/global/_common.module.scss';
import { BackGround } from '../../../components/background';
import { Link, useIntl } from "gatsby-plugin-react-intl"
import {passwordRegist} from "../../../services/auth";
import {navigate} from "gatsby-plugin-react-intl";
import {postAPI} from "../../../services/fetch";
import LoadingElement from "../../../components/LoadingElement";
import * as LoadingElementStyles from '../../../styles/components/_loading_element.module.scss';

const PasswordResetPage = ({ location }) => {
  const intl = useIntl()
  const t = (id) => intl.formatMessage({id})

  const [isLoading, setIsLoading] = React.useState(false)
  const [mailaddress, setMailaddress] = React.useState('')
  const [errorMailaddress, setErrorMailaddress] = React.useState({isError: false, message: ''});

  const onClickResetPassword = async () => {
    if (mailaddress === '') {
      setErrorMailaddress({isError: true, message: t('users.password.reset.error.pleaseMailaddress')})
      return
    }
    setErrorMailaddress({isError: false, message: ''})

    setIsLoading(true)
    try {
      const response = await postAPI({ url: '/api/user/password/forgot', params: { email: mailaddress }})
      if (response.data.result === 0) {
        await navigate('/users/password/sendmail')
      } else {
        setErrorMailaddress({ isError: true, message: t('users.loginSetting.error.registrationError') })
        setIsLoading(false)
      }
    } catch (e) {
      console.error(e)
      setErrorMailaddress({ isError: true, message: t('users.loginSetting.error.registrationError') })
      setIsLoading(false)
    }
  }

  return (
    <Layout location={location}>
      <div className={isLoading ? '' : LoadingElementStyles.hidden}>
        <LoadingElement />
      </div>
      <Seo title={t('users.password.reset.seo.title')} description={t('users.password.reset.seo.description')} />
      <BackGround>
        <section className={CommonStyles.wrapper}>
          <div className={CommonStyles.container}>
            <div id="users" className={Styles.users}>
              <h1>{t('users.password.reset.title')}</h1>
              <section>
                <div className={Styles.mg_b2}>
                  {t('users.password.reset.message1')}<br/>
                  {t('users.password.reset.message2')}
                </div>
                <div className={Styles.mg_b2}>
                  <div>
                    <label htmlFor="user_email">{t('users.password.reset.mailaddress')}</label>
                    <input
                      id="user_email"
                      value={mailaddress}
                      onChange={(e) => setMailaddress(e.target.value)}
                      autoFocus="autofocus"
                      type="email"
                    />
                  </div>
                  <div className={`${Styles.error_message} ${(errorMailaddress.isError) && Styles.is_error}`}>{errorMailaddress.message}</div>
                  <div className={Styles.text_center}>
                    <button
                      type="button"
                      className={Styles.btn_primary}
                      onClick={onClickResetPassword}
                    >
                      {t('users.password.reset.sendResetMail')}
                    </button>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </section>
      </BackGround>
    </Layout>
  );
};

export default PasswordResetPage;
